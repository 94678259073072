import clone from "clone";
import { newInvoice, newService, newReport } from "./config";
import actions from "./actions";

const initState = {
  invoices: {},
  services: {},
  reports: {},
  savedsuccefull: false,
  isLoading: false,
  errorMessage: false,
  initialInvoices: true,
  currentInvoice: {},
  editableInvoice: {},
  currentService: {},
  editableService: {},
  currentReport: {},
  editableReport: {},
  isNewInvoice: false,
  isNewReport: false,
  enableEditView: false,
  previewVisible: false,
  previewImage: "",
  previewTitle: "",
};
export default function reducer(state = initState, { type, ...payload }) {
  switch (type) {
    case actions.SELECT_CURRENT_INVOICE: {
      const invoices = state.invoices;
      const dataSource = [];
      Object.keys(invoices).map((invoice, index) => {
        return dataSource.push({
          ...invoices[invoice],
        });
      });
      const index = dataSource.map((invoice) => invoice.id).indexOf(payload.id);
      const isNewInvoice = index === -1;
      const currentInvoice = isNewInvoice
        ? {
            id: payload.id,
            key: payload.id,
            ...newInvoice,
          }
        : dataSource[index];
      const enableEditView = isNewInvoice;
      return {
        ...state,
        currentInvoice,
        enableEditView,
        isNewInvoice,
        editableInvoice: clone(currentInvoice),
      };
    }

    case actions.SELECT_CURRENT_SERVICE: {
      const services = state.services;
      const dataSource = [];
      Object.keys(services).map((service, index) => {
        return dataSource.push({
          ...services[service],
          key: index,
        });
      });
      const index = dataSource
        .map((invoice) => invoice.SERVICE)
        .indexOf(payload.SERVICE);
      const isNewService = index === -1;
      const currentService = isNewService
        ? {
            id: payload.SERVICE,
            key: payload.SERVICE,
            ...newService,
          }
        : dataSource[index];
      return {
        ...state,
        id: payload.SERVICE,
        currentService,
        editableService: clone(currentService),
      };
    }

    case actions.SELECT_CURRENT_REPORT: {
      const reports = state.reports;
      const dataSource = [];
      Object.keys(reports).map((report, index) => {
        return dataSource.push({
          ...reports[report],
          key: index,
        });
      });
      const index = dataSource.map((invoice) => invoice.id).indexOf(payload.id);
      const isNewReport = index === -1;
      const currentReport = isNewReport
        ? {
            id: payload.id,
            key: payload.id,
            ...newReport,
          }
        : dataSource[index];
      return {
        ...state,
        currentReport,
        editableReport: clone(currentReport),
      };
    }

    case actions.SELECT_CURRENT_REPORT_INTEGRATED: {
      const invoices = state.invoices;
      const dataSource = [];
      Object.keys(invoices).map((invoice, index) => {
        return dataSource.push({
          ...invoices[invoice],
        });
      });
      const index = dataSource.map((invoice) => invoice.id).indexOf(payload.id);
      const isNewInvoice = index === -1;
      const currentReport = isNewInvoice
        ? {
            id: payload.id,
            key: payload.id,
            ...newInvoice,
          }
        : dataSource[index];
      const enableEditView = isNewInvoice;
      return {
        ...state,
        currentReport,
        enableEditView,
        isNewInvoice,
        editableReport: clone(currentReport),
      };
    }

    case actions.UPDATE_EDIT_INVOICE:
      return {
        ...state,
        savedsuccefull: true,
        isLoading: true,
        editableInvoice: clone(payload.invoice),
      };
    case actions.UPDATE_EDIT_REPORT:
      return {
        ...state,
        savedsuccefull: true,
        isLoading: true,
        editableReport: clone(payload.report),
      };
    case actions.UPDATE_EDIT_SERVICE:
      return {
        ...state,
        editableService: clone(payload.service),
      };
    case actions.UPDATE_SERVICE:
      return {
        ...state,
        id: payload.SERVICE,
        key: payload.SERVICE,
        currentService: clone(payload.service),
      };
    case actions.UPDATE_INVOICE:
      return {
        ...state,
        isLoading: true,
        savedsuccefull: true,
        currentInvoice: clone(payload.invoice),
      };
    case actions.UPDATE_REPORT:
      return {
        ...state,
        isLoading: true,
        savedsuccefull: true,
        currentReport: clone(payload.report),
      };
    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: payload.view,
        editableInvoice: clone(state.currentInvoice),
      };
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        savedsuccefull: false,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentInvoice: false,
        invoices: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "Internet Conection Lost",
      };

    //reports
    case actions.LOAD_FROM_FIRESTORE_REPORTS:
      return {
        ...state,
        savedsuccefull: false,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_REPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentInvoice: false,
        reports: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_REPORTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "Internet Conection Lost",
      };

    case actions.LOAD_FROM_FIRESTORE_SERVICE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        services: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SERVICE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.LOAD_FROM_FIRESTORE_RECICLE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_RECICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        invoices: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_RECICLE_ERROR:
      return {
        ...state,
        invoices: {},
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        investor: payload.data == null ? initState.investor : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        invoices: payload.data,
      };
    case actions.RESET_FIRESTORE_DOCUMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    default:
      return state;
  }
}
