const newInvoice = {
  orderStatus: 'Pending',
  orderDate: new Date().getTime(),
  currency: '$',
  TECHNICIAN: 'CHRISTIAN BELLO',
  LANGUAGE: 'ENGLISH',
  enableEditView: false,
  previewVisible: false,
  previewImage: '',
  previewTitle: '',
  fileList: [],
  PROPOSALCONTENT: '  ',
  Conctract: '  ',
  invoiceList: [
    {
      key: 1,
      itemName: '',
      costs: 0,
      qty: 0,
      price: 0,
    },
  ],
  subTotal: 0,
  COMPLETIONTIME: 0,
  overhead:0,
  Material:0,
  porcentaje:0,
  totalCost: 0,
  deleted_at: null,
  
};
const newService = {
  invoiceList: [
    {
      key: 1,
      itemName: '',
      costs: 0,
      qty: 0,
      price: 0,
    },
  ],
  deleted_at: null,
};

const newReport = {
  orderStatus: 'Pending',
  orderDate: new Date().getTime(),
  currency: '$',
  TECHNICIAN: 'CHRISTIAN BELLO',
  LANGUAGE: 'ENGLISH',
  enableEditView: false,
  previewVisible: false,
  previewImage: '',
  previewTitle: '',
  fileList: [],
  PROPOSALCONTENT: '  ',
  Conctract: '  ',
  invoiceList: [
    {
      key: 1,
      itemName: '',
      costs: 0,
      qty: 0,
      price: 0,
    },
  ],
  subTotal: 0,
  COMPLETIONTIME: 0,
  overhead:0,
  Material:0,
  porcentaje:0,
  totalCost: 0,
  created: 'RoofingNation',
  deleted_at: null,
  
};



export {
  newInvoice,
  newReport,
  newService,
};
