const actions = {
  //Firestore actions
  LOAD_FROM_FIRESTORE: "INVOICE_LOAD_FROM_FIRESTORE",
  LOAD_FROM_FIRESTORE_SUCCESS: "INVOICE_LOAD_FROM_FIRESTORE_SUCCESS",
  LOAD_FROM_FIRESTORE_ERROR: "INVOICE_LOAD_FROM_FIRESTORE_ERROR",

  SAVE_INTO_FIRESTORE: "INVOICE_SAVE_INTO_FIRESTORE",
  SAVE_INTO_FIRESTORE_ERROR: "INVOICE_SAVE_INTO_FIRESTORE_ERROR",

  RESET_FIRESTORE_DOCUMENTS: "INVOICE_RESET_FIRESTORE_DOCUMENTS",
  RESET_FIRESTORE_DOCUMENTS_ERROR: "INVOICE_RESET_FIRESTORE_DOCUMENTS_ERROR",

  TOGGLE_FIRESTORE_HANDLE_MODAL: "INVOICE_TOGGLE_FIRESTORE_HANDLE_MODAL",
  FIRESTORE_UPDATE: "INVOICE_FIRESTORE_UPDATE",

  STORE_INTO_FIRESTORE: "INVOICE_STORE_INTO_FIRESTORE",

  DELETE_INVOICE_SAGA: "INVOICE_DELETE_INVOICE_SAGA",
  DELETE_SERVICE_SAGA: "INVOICE_DELETE_SERVICE_SAGA",
  GET_INVOICE: "INVOICE_GET_INVOICE",

  UPDATE_INVOICE: "INVOICE_UPDATE_INVOICE",
  UPDATE_INVOICE_SAGA: "INVOICE_UPDATE_INVOICE_SAGA",

  UPDATE_SERVICE: "INVOICE_UPDATE_SERVICE",
  UPDATE_SERVICE_SAGA: "INVOICE_UPDATE_SERVICE_SAGA",

  UPDATE_REPORTS: "INVOICE_UPDATE_REPORTS",
  UPDATE_REPORTS_SAGA: "INVOICE_UPDATE_REPORTS_SAGA",

  SELECT_CURRENT_INVOICE: "INVOICE_SELECT_CURRENT_INVOICE",
  SELECT_CURRENT_SERVICE: "INVOICE_SELECT_CURRENT_SERVICE",
  SELECT_CURRENT_REPORT: "INVOICE_SELECT_CURRENT_REPORT",
  SELECT_CURRENT_REPORT_INTEGRATED: "INVOICE_SELECT_CURRENT_REPORT_INTEGRATED",
  TOGGLE_VIEW: "INVOICE_INVOICE_TOGGLE_VIEW",
  UPDATE_EDIT_INVOICE: "INVOICE_INVOICE_UPDATE_EDIT_INVOICE",
  UPDATE_EDIT_SERVICE: "INVOICE_UPDATE_EDIT_SERVICE",
  UPDATE_EDIT_REPORT: "INVOICE_UPDATE_EDIT_REPORT",

  RECICLE_INVOICE_SAGA: "INVOICE_RECICLE_INVOICE_SAGA",
  LOAD_FROM_FIRESTORE_RECICLE: "INVOICE_LOAD_FROM_FIRESTORE_RECICLE",
  LOAD_FROM_FIRESTORE_RECICLE_SUCCESS:
    "INVOICE_LOAD_FROM_FIRESTORE_RECICLE_SUCCESS",
  LOAD_FROM_FIRESTORE_RECICLE_ERROR:
    "INVOICE_LOAD_FROM_FIRESTORE_RECICLE_ERROR",
  LOAD_FROM_FIRESTORE_SERVICE: "INVOICE_LOAD_FROM_FIRESTORE_SERVICE",
  LOAD_FROM_FIRESTORE_SERVICE_SUCCESS:
    "INVOICE_LOAD_FROM_FIRESTORE_SERVICE_SUCCESS",
  LOAD_FROM_FIRESTORE_SERVICE_ERROR:
    "INVOICE_LOAD_FROM_FIRESTORE_SERVICE_ERROR",

  LOAD_FROM_FIRESTORE_REPORTS: "INVOICE_LOAD_FROM_FIRESTORE_REPORTS",
  LOAD_FROM_FIRESTORE_REPORTS_SUCCESS:
    "INVOICE_LOAD_FROM_FIRESTORE_REPORTS_SUCCESS",
  LOAD_FROM_FIRESTORE_REPORTS_ERROR:
    "INVOICE_LOAD_FROM_FIRESTORE_REPORTS_ERROR",

  MONDAY_LOAD: "INVOICE_MONDAY_LOAD",

  mondayload: () => {
    return { type: actions.MONDAY_LOAD };
  },

  loadFromFireStoreRecicle: () => {
    return { type: actions.LOAD_FROM_FIRESTORE_RECICLE };
  },

  loadFromFireStoreRecicleSuccess: (data) => ({
    type: actions.LOAD_FROM_FIRESTORE_RECICLE_SUCCESS,
    data: data,
  }),

  loadFromFireStoreRecicleError: (error) => ({
    type: actions.LOAD_FROM_FIRESTORE_RECICLE_ERROR,
    payload: { error },
  }),

  loadFromFireStoreService: () => {
    return { type: actions.LOAD_FROM_FIRESTORE_SERVICE };
  },

  loadFromFireStoreServiceSuccess: (data) => ({
    type: actions.LOAD_FROM_FIRESTORE_SERVICE_SUCCESS,
    data: data,
  }),

  loadFromFireStoreServiceError: (error) => ({
    type: actions.LOAD_FROM_FIRESTORE_SERVICE_ERROR,
    payload: { error },
  }),

  //reports
  loadFromFireStoreReports: () => {
    return { type: actions.LOAD_FROM_FIRESTORE_REPORTS };
  },

  loadFromFireStoreReportsSuccess: (data) => ({
    type: actions.LOAD_FROM_FIRESTORE_REPORTS_SUCCESS,
    data: data,
  }),

  loadFromFireStoreReportsError: (error) => ({
    type: actions.LOAD_FROM_FIRESTORE_REPORTS_ERROR,
    payload: { error },
  }),

  storeIntoFireStoreReports: (data) => ({
    type: actions.STORE_INTO_FIRESTORE_REPORTS,
    data: data,
  }),

  saveIntoFireStoreReports: (data) => ({
    type: actions.SAVE_INTO_FIRESTORE_REPORTS,
    data: data,
  }),

  // updateReport: (data) => ({
  //   type: actions.UPDATE_INVOICE_REPORTS,
  //   data: data,
  // }),

  saveIntoFireStoreReportsError: (error) => ({
    type: actions.SAVE_INTO_FIRESTORE_REPORTS_ERROR,
    payload: { error },
  }),

  loadFromFireStore: () => {
    return { type: actions.LOAD_FROM_FIRESTORE };
  },

  loadFromFireStoreSuccess: (data) => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    data: data,
  }),

  loadFromFireStoreError: (error) => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  storeIntoFirestore: (id, actionName) => ({
    type: actions.STORE_INTO_FIRESTORE,
  }),

  saveIntoFireStore: (data, actionName = "insert") => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data, actionName },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
    payload: { data },
  }),

  update: (data) => ({
    type: actions.FIRESTORE_UPDATE,
    payload: { data },
  }),

  saveIntoFireStoreError: (error) => ({
    type: actions.SAVE_INTO_FIRESTORE_ERROR,
    payload: { error },
  }),

  resetFireStoreDocuments: () => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS,
  }),

  resetFireStoreDocumentsError: (error) => ({
    type: actions.RESET_FIRESTORE_DOCUMENTS_ERROR,
    payload: { error },
  }),

  initData: () => ({ type: actions.GET_INVOICE }),

  deleteInvoice: (selected) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.DELETE_INVOICE_SAGA,
        invoices: selected,
      });
    };
  },

  deleteService: (selected) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.DELETE_SERVICE_SAGA,
        services: selected,
      });
    };
  },

  RecicleInvoice: (selected) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.RECICLE_INVOICE_SAGA,
        invoices: selected,
      });
    };
  },

  updateInvoice: (invoice) => {
    return (dispatch, getState) => {
      let INVOICE = getState().Invoices.invoices;
      const invoices = Object.keys(INVOICE).map((key) => [
        Number(key),
        INVOICE[key],
      ]);
      const index = invoices.map((inv) => inv.id).indexOf(invoice.id);
      if (index === -1) {
        invoices.push(invoice);
      } else {
        invoices[index] = invoice;
      }
      dispatch({
        type: actions.UPDATE_INVOICE_SAGA,
        invoices,
        invoice,
      });
    };
  },

  updateService: (service) => {
    return (dispatch, getState) => {
      let SERVICE = getState().Invoices.services;
      const services = Object.keys(SERVICE).map((key) => [
        Number(key),
        SERVICE[key],
      ]);
      const index = services.map((inv) => inv.SERVICE).indexOf(service.SERVICE);
      if (index === -1) {
        services.push(service);
      } else {
        services[index] = service;
      }
      dispatch({
        type: actions.UPDATE_SERVICE_SAGA,
        services,
        service,
      });
    };
  },

  updateReport: (report) => {
    return (dispatch, getState) => {
      let REPORT = getState().Invoices.reports;
      const reports = Object.keys(REPORT).map((key) => [
        Number(key),
        REPORT[key],
      ]);
      const index = reports.map((inv) => inv.id).indexOf(report.id);
      if (index === -1) {
        reports.push(report);
      } else {
        reports[index] = report;
      }
      dispatch({
        type: actions.UPDATE_REPORTS_SAGA,
        reports,
        report,
      });
    };
  },

  selectCurrentInvoice: (id) => ({ type: actions.SELECT_CURRENT_INVOICE, id }),

  selectCurrentService: (SERVICE) => ({
    type: actions.SELECT_CURRENT_SERVICE,
    SERVICE,
  }),

  selectCurrentReport: (id) => ({ type: actions.SELECT_CURRENT_REPORT, id }),

  selectCurrentReportIntegrated: (id) => ({ type: actions.SELECT_CURRENT_REPORT_INTEGRATED, id }),

  toggleView: (view) => ({ type: actions.TOGGLE_VIEW, view }),

  editInvoice: (invoice) => ({ type: actions.UPDATE_EDIT_INVOICE, invoice }),

  editService: (service) => ({ type: actions.UPDATE_EDIT_SERVICE, service }),

  editReport: (report) => ({ type: actions.UPDATE_EDIT_REPORT, report }),
};
export default actions;
