import { all, takeLatest, put, call } from "redux-saga/effects";
import actions from "./actions";
import omit from "lodash/omit";

import { rsf, db } from "../../library/firebase/firebase";
import {
  convertCollectionsSnapshotToMap,
  deleteDocuments,
  addCollectionAndDocuments,
} from "../../library/firebase/firebase.util";

const COLLECTION_NAME = "Estimates"; // change your collection
const ORDER_BY = "id";
const ORDER = "desc";

function* loadFromFirestore() {
  try {
    const collectionRef = db
      .collection(COLLECTION_NAME)
      .where("deleted_at", "==", null)
      .orderBy(ORDER_BY, ORDER)
      .limit(100);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadFromFireStoreReports() {
  try {
    const collectionRef = db
      .collection("Reports")
      .where("deleted_at", "==", null)
      .orderBy(ORDER_BY, ORDER)
      .limit(100);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadFromFireStoreReportsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreReportsError(error));
  }
}

function* loadFromFirestoreService() {
  try {
    const collectionRef = db
      .collection("services")
      .where("deleted_at", "==", null)
      .limit(100);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadFromFireStoreServiceSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreServiceError(error));
  }
}

function* loadFromFirestoreRecicle() {
  try {
    const collectionRef = db
      .collection(COLLECTION_NAME)
      .where("deleted_at", "!=", null)
      .orderBy("deleted_at", ORDER)
      .limit(100);
    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadFromFireStoreRecicleSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreRecicleError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { id, actionName } = payload;
  try {
    switch (actionName) {
      case "delete":
        yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${id}`, {
          deleted_at: new Date().getTime(),
        });
        break;
      case "update":
        yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${id}`, {
          ...omit(id, ["id"]),
        });
        break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME, id);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

function* resetFireStoreDocuments() {
  try {
    yield call(deleteDocuments, COLLECTION_NAME);
  } catch (error) {
    console.log(error);
  }
}

export function* deleteServiceSaga({ services }) {
  yield call(
    rsf.firestore.setDocument,
    `services/${services["0"]}`,
    {
      deleted_at: new Date().getTime(),
    },
    { merge: true }
  );
  yield put({ type: actions.LOAD_FROM_FIRESTORE_SERVICE });
}

export function* deleteInvoiceSaga({ invoices }) {
  yield call(
    rsf.firestore.setDocument,
    `${COLLECTION_NAME}/${invoices["0"]}`,
    {
      deleted_at: new Date().getTime(),
    },
    { merge: true }
  );
  yield put({ type: actions.LOAD_FROM_FIRESTORE });
}
export function* RecicleInvoiceSaga({ invoices }) {
  yield call(
    rsf.firestore.setDocument,
    `${COLLECTION_NAME}/${invoices["0"]}`,
    {
      deleted_at: null,
    },
    { merge: true }
  );
  yield put({ type: actions.LOAD_FROM_FIRESTORE_RECICLE });
}

export function* updateInvoiceSaga({ invoices, invoice }) {
  try {
    yield call(
      rsf.firestore.setDocument,
      `${COLLECTION_NAME}/${invoice.id}`,
      invoice,
      { merge: true }
    );
    yield put({
      type: actions.UPDATE_INVOICE,
      enableEditView: false,
      invoices,
      invoice,
    });
  } catch (error) {
    console.log(error);
    console.log(invoice);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

export function* updateServiceSaga({ services, service }) {
  yield call(
    rsf.firestore.setDocument,
    `services/${service.SERVICE}`,
    service,
    { merge: true }
  );
  yield put({
    type: actions.UPDATE_SERVICE,
    services,
    service,
  });
}

export function* updateReportsSaga({ reports, report }) {
  yield call(rsf.firestore.setDocument, `Reports/${report.id}`, report, {
    merge: true,
  });
  yield put({
    type: actions.UPDATE_REPORTS,
    reports,
    report,
  });
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.STORE_INTO_FIRESTORE, storeIntoFirestore),
    yield takeLatest(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    yield takeLatest(
      actions.LOAD_FROM_FIRESTORE_REPORTS,
      loadFromFireStoreReports
    ),
    yield takeLatest(
      actions.LOAD_FROM_FIRESTORE_SERVICE,
      loadFromFirestoreService
    ),
    yield takeLatest(
      actions.LOAD_FROM_FIRESTORE_RECICLE,
      loadFromFirestoreRecicle
    ),
    yield takeLatest(actions.UPDATE_INVOICE_SAGA, updateInvoiceSaga),
    yield takeLatest(actions.UPDATE_SERVICE_SAGA, updateServiceSaga),
    yield takeLatest(actions.UPDATE_REPORTS_SAGA, updateReportsSaga),
    yield takeLatest(actions.DELETE_INVOICE_SAGA, deleteInvoiceSaga),
    yield takeLatest(actions.DELETE_SERVICE_SAGA, deleteServiceSaga),
    yield takeLatest(actions.RECICLE_INVOICE_SAGA, RecicleInvoiceSaga),
  ]);
}
